<template>
  <div class="huodongPage">
    <div class="appPreview" :key="pageKey">
      <div class="appPreviewPage" :style="`backgroundColor:${imgForm.backgroundColor}`">
        <el-image
          v-if="imgForm.activityDetailImgurl[0]"
          style="width: 100%"
          :src="imgForm.activityDetailImgurl[0].url"
          fit="contain"></el-image>
        <div style="padding: 0 15px">
          <div class="productCard">
            <div class="productCard-left">
              <p>限时特价</p>
              <p class="bigTxt">￥{{ruleForm.discountValue}}
                <span v-if="editFlag" class="smallTxt ml10 deleteTxt">原价￥{{ruleForm.product.price}}</span>
                <span v-else class="smallTxt ml10 deleteTxt">原价￥{{yj}}</span>
              </p>
            </div>
            <div class="productCard-right">
              <p>距离活动开始</p>
              <div class="countdown-box">{{day}}天
                <span class="timeCard">{{hour}}</span>:
                <span class="timeCard">{{min}}</span>:
                <span class="timeCard">{{second}}</span>
              </div>
            </div>
          </div>
          <div class="myCard">
            <p class="myCard-title">{{ruleForm.activityName || '--'}}</p>
            <div class="rankbox">
              <div class="rankbox-left">
                <div class="rankItem"
                  v-for="(item, i) in rankList.slice(0, 3)"
                  :key="i">
                  <img class="rankImg" :src="require(`../../assets/rank${i+1}.png`)">
                  <img class="avatar" :src="item.avatar"/>
                </div>
                <div class="rankMore">
                  <div class="rankItem rankMore-item"
                    v-for="(item, i) in rankList"
                    :key="i">
                    <img class="avatar" :src="item.avatar"/>
                  </div>
                  <div class="rankItem rankMore-item rankMore-item-last">
                    ...
                  </div>
                </div>
              </div>
              <div class="recordTxt">
                <span>1200</span>次浏览
              </div>
            </div>
          </div>
          <div class="myBtn-box">
            <p class="myBtn" :style="`backgroundColor:${imgForm.titleColor}`">最新购买列表</p>
          </div>
          <div class="myCard">
            <p class="myCard-title hasborder textCenter lineHeight20">
              共<span class="redTxt">305</span>人参与购买
            </p>
            <div class="card-list">
              <div class="card-list-item"
                v-for="(item, i) in rankList.slice(0, 3)"
                :key="i">
                <div class="card-list-item-left">
                  <img :src="item.avatar"/>
                  <div class="info">
                    <p>{{item.name}}</p>
                    <p>{{item.phone}}</p>
                  </div>
                </div>
                <div class="card-list-item-right">
                  <p class="redTxt">{{item.amount}}{{item.status}}</p>
                  <p>{{item.time}}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="myBtn-box">
            <p class="myBtn" :style="`backgroundColor:${imgForm.titleColor}`">收益排行榜</p>
          </div>
          <div class="myCard">
            <p class="myCard-title hasborder lineHeight20 textblod">
              <span class="inline-block" style="width: 40px;margin-right: 10px">排名</span>
              <span class="inline-block">用户名</span>
              <span class="inline-block" style="float: right">收益</span>
            </p>
            <div class="card-list card-list2">
              <div class="card-list-item"
                v-for="(item, i) in rankList.slice(0, 3)"
                :key="i">
                <div class="card-list-item-left">
                  <img class="rankImg" :src="require(`../../assets/rank${i+1}.png`)">
                  <img :src="item.avatar"/>
                  <div class="info">
                    {{item.name}}
                  </div>
                </div>
                <div class="card-list-item-right">
                  <p class="redTxt">累计{{item.income}}元</p>
                </div>
              </div>
            </div>
          </div>
          <div class="myBtn-box">
            <p class="myBtn" :style="`backgroundColor:${imgForm.titleColor}`">活动介绍</p>
          </div>
        </div>
        <template v-if="imgForm.activityDetailImgurl.length>0">
          <el-image
            v-for="(item,i) in imgForm.activityDetailImgurl"
            :key="i"
            style="width: 100%"
            :src="item.url"
            fit="contain"></el-image>
        </template>
      </div>
    </div>
    <div class="formBox">
      <el-tabs v-model="tabIndex" type="border-card" @tab-click="tabChange">
        <el-tab-pane label="活动配置" name="0">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="ruleForm">
            <el-form-item label="商家" prop="masterCode">
              <el-select
                v-model="ruleForm.masterCode"
                placeholder="请选择"
                style="width:200px;margin-right: 10px"
                :disabled="editFlag || allDisable"
                clearable
                @change="masterChange">
                <el-option v-for="(item, i) in masterList"
                  :key="i"
                  :label="item.masterName"
                  :value="item.masterCode"></el-option>
              </el-select>
              <el-select v-model="ruleForm.shopCodesList" placeholder="请选择" multiple collapse-tags style="width:200px"  :disabled="allDisable" clearable>
                <el-option v-for="(item, i) in shopList"
                :key="i"
                :label="item.shopName"
                :value="String(item.shopCode)"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="产品名称" prop="productName">
              <el-select v-model="ruleForm.productCode"
                placeholder="请选择"
                style="width:200px;margin-right: 10px"
                @change="productChange"
                :disabled="allDisable"
                clearable>
                <el-option v-for="(item, i) in productList"
                :key="i"
                :label="item.productName"
                :value="item.productCode"></el-option>
              </el-select>
              <el-button type="primary" icon="el-icon-plus" :disabled="allDisable" @click="openPro">添加产品</el-button>
            </el-form-item>
            <el-form-item label="活动名称" prop="activityName">
              <el-input v-model="ruleForm.activityName" placeholder="请输入活动名称" style="width:260px;" :disabled="allDisable"></el-input>
            </el-form-item>
            <el-form-item label="排序" prop="sort">
              <el-input-number v-model="ruleForm.sort" controls-position="right" style="width:260px;" :disabled="allDisable"></el-input-number>
            </el-form-item>
            <el-form-item label="开始时间" prop="startDate">
              <el-date-picker
                type="datetime"
                placeholder="请选择"
                v-model="ruleForm.startDate"
                style="width: 260px;"
                value-format="yyyy-MM-dd HH:mm:ss"
                :disabled="allDisable"></el-date-picker>
            </el-form-item>
            <el-form-item label="有效期至" prop="expireDate">
              <el-date-picker
              type="datetime"
              placeholder="请选择"
              v-model="ruleForm.expireDate"
              style="width: 260px;"
              :disabled="allDisable"
              value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
            </el-form-item>
            <el-form-item label="是否开启拼团">
              <el-form-item prop="isGroup">
                <el-switch
                  :active-value="1"
                  :inactive-value="0"
                  v-model="ruleForm.isGroup"
                  :disabled="allDisable">
                </el-switch>
              </el-form-item>
            </el-form-item>
            <template v-if="ruleForm.isGroup == 1">
              <el-form-item label="开团价" prop="openGroup">
                <el-input v-model="ruleForm.openGroup" placeholder="请输入" style="width:260px;" :disabled="allDisable">
                  <template slot="append">元</template>
                </el-input>
                <el-checkbox
                  v-model="ruleForm.isContrast"
                  true-label="1"
                  false-label="0">是否成团返差额价(仅限开团价比活动价高的情况下返)</el-checkbox>
              </el-form-item>
            </template>
            <el-form-item :label="ruleForm.isGroup == 1 ? '参团价' : '抢购价'" prop="discountValue">
              <el-input v-model="ruleForm.discountValue" placeholder="请输入" style="width:260px;" :disabled="allDisable">
                <template slot="append">元</template>
              </el-input>
            </el-form-item>
            <el-form-item label="每人限购" prop="everyoneNumber">
              <el-input-number v-model="ruleForm.everyoneNumber" controls-position="right" style="width:260px;" :disabled="allDisable"></el-input-number>
              (0代表无限制)
            </el-form-item>
            <el-form-item label="库存数量" prop="stockNumber">
              <el-input-number v-model="ruleForm.stockNumber" controls-position="right" style="width:260px;" :disabled="allDisable"></el-input-number>
            </el-form-item>
            <el-form-item label="下单是否选择备注项">
              <el-form-item prop="remarksStart">
                <el-switch
                  active-value="1"
                  inactive-value="0"
                  v-model="ruleForm.remarksStart"
                  :disabled="allDisable">
                </el-switch>
                <template v-if="ruleForm.remarksStart == 1" >
                  <br>
                  <el-button
                    size="mini"
                    type="primary"
                    @click="openRemark"
                    :disabled="allDisable">新增备注项</el-button>
                    <el-tag
                      :key="n"
                      v-for="(tag, n) in remarksList"
                      closable
                      :disable-transitions="false"
                      @click="openRemark(tag)"
                      @close="deleteRemark(n)">
                      {{tag.remarksName}}
                    </el-tag>
                </template>
              </el-form-item>
            </el-form-item>
            <el-form-item label="是否上架">
              <el-form-item prop="online">
                <el-switch v-model="ruleForm.online"
                  :disabled="allDisable"
                  :active-value="1"
                  :inactive-value="0">
                </el-switch>
              </el-form-item>
            </el-form-item>
            <el-form-item label="是否开启音乐">
              <el-form-item prop="music">
                <el-switch
                  :active-value="1"
                  :inactive-value="0"
                  v-model="ruleForm.music"
                  :disabled="allDisable">
                </el-switch>
              </el-form-item>
            </el-form-item>
            <el-form-item label="音乐类型">
              <el-form-item prop="musictype">
                <el-radio-group v-model="ruleForm.musictype" :disabled="allDisable">
                  <el-radio :label="0">默认音乐</el-radio>
                  <el-radio :label="1">播报音乐</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-form-item>
            <el-form-item label="交易手续费" prop="platCmsn">
              <el-input v-model="ruleForm.platCmsn" placeholder="请输入" style="width:260px;" :disabled="allDisable">
                <template slot="append">元</template>
              </el-input>
              <br>
              (默认值：平台抽佣=0.6%+开发服务费0元)
            </el-form-item>
            <el-form-item label="活动前">
              <el-input v-model="ruleForm.giftQty" placeholder="请输入" style="width:120px;margin-right:10px" :disabled="allDisable"></el-input>
              名赠送
              <el-input v-model="ruleForm.giftProduct" style="width:260px;" :disabled="allDisable"></el-input>
            </el-form-item>
            <el-form-item label="温馨提示">
              <el-input v-model="ruleForm.tips" style="width:260px;" :disabled="allDisable"></el-input>
            </el-form-item>
            <el-form-item label="是否开启红包">
              <el-form-item prop="hongbaoEnable">
                <el-switch
                  v-model="ruleForm.hongbaoEnable"
                  :disabled="editFlag || allDisable"
                  :active-value="1"
                  :inactive-value="0">
                </el-switch>
              </el-form-item>
            </el-form-item>
            <template v-if="ruleForm.hongbaoEnable == 1">
              <el-form-item label="好友下单" prop="shareCmsn">
                <el-input v-model="ruleForm.shareCmsn" placeholder="请输入" style="width:360px;" :disabled="allDisable">
                  <template slot="append">元</template>
                </el-input>
              </el-form-item>
              <el-form-item label="二级奖励" prop="shareCmsnTwo">
                <el-input v-model="ruleForm.shareCmsnTwo" placeholder="请输入" style="width:360px;" :disabled="allDisable">
                  <template slot="append">元</template>
                </el-input>
              </el-form-item>
              <el-form-item label="首单奖励" prop="firstOrderReward">
                <el-input v-model="ruleForm.firstOrderReward" placeholder="请输入" style="width:360px;" :disabled="allDisable">
                  <template slot="append">元，下单成功赠送该金额的红包</template>
                </el-input>
              </el-form-item>
              <el-form-item label="拆红包奖励" prop="hongbao">
                <el-input v-model="ruleForm.hongbao" placeholder="请输入" style="width:360px;" :disabled="allDisable">
                  <template slot="append">元，进入活动就送该金额的红包</template>
                </el-input>
              </el-form-item>
              <el-form-item label="首次提现限额" prop="limitWithdraw">
                <el-input v-model="ruleForm.limitWithdraw" placeholder="请输入" style="width:360px;" :disabled="allDisable">
                  <template slot="append">元，固定</template>
                </el-input>
              </el-form-item>
              <el-form-item label="后续提现限额" prop="afterLimitWithdraw">
                <el-input v-model="ruleForm.afterLimitWithdraw" placeholder="请输入" style="width:360px;" :disabled="allDisable">
                  <template slot="append">元的倍数提现，0不限，建议200</template>
                </el-input>
              </el-form-item>
            </template>
            <el-form-item label="是否开启虚拟销量">
              <el-form-item prop="virtually">
                <el-switch
                  v-model="ruleForm.virtually"
                  :disabled="allDisable"
                  :active-value="1"
                  :inactive-value="0">
                </el-switch>
              </el-form-item>
            </el-form-item>
            <template v-if="ruleForm.virtually == 1">
              <el-form-item label="虚拟浏览数" prop="browseNum">
                <el-input v-model="ruleForm.browseNum" placeholder="请输入" style="width:360px;" :disabled="allDisable"></el-input>
              </el-form-item>
              <el-form-item label="虚拟购买数" prop="saleNum">
                <el-input v-model="ruleForm.saleNum" placeholder="请输入" style="width:360px;" :disabled="allDisable"></el-input>
              </el-form-item>
            </template>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="图片配置" name="1">
          <el-form :model="imgForm" :rules="imgFormRules" ref="imgForm" label-width="150px" class="ruleForm">
            <el-form-item label="活动主图" prop="activityDetailImgurl">
              <p style="color:#F56C6C">拖拽图片可更换图片排列顺序</p>
              <div style="display: flex;flex-wrap: wrap;">
                <draggable
                  :move="imgMove"
                  :list="imgForm.activityDetailImgurl"
                  :animation="200"
                  style="display: flex;flex-wrap: wrap;"
                >
                <div v-for="(img, i) in imgForm.activityDetailImgurl" :key="i" class="imgList-box">
                  <i class="el-icon-error img-delete" @click="imgDelete(i, 'activityDetailImgurl')"></i>
                  <el-image
                    class="imgList-item"
                    :src="img.url"
                    fit="contain"></el-image>
                </div>
                </draggable>
                <el-upload
                  class="uploader"
                  :disabled="allDisable"
                  :action="$baseUrl+'/back/common/image/upload'"
                  :headers="$headers"
                  :show-file-list="false"
                  :on-success="uploadSuccess"
                  :on-change="uploadChange">
                  <i class="el-icon-plus uploader-icon"></i>
                </el-upload>
              </div>
            </el-form-item>
            <el-form-item label="活动首页列表图" prop="activityImgurl">
              <p>建议尺寸750*380</p>
              <div style="display: flex;flex-wrap: wrap;">
                <div v-for="(img, i) in imgForm.activityImgurl" :key="i" class="imgList-box">
                  <i class="el-icon-error img-delete" @click="imgDelete(i, 'activityImgurl')"></i>
                  <el-image
                    class="imgList-item"
                    :src="img.url"
                    fit="contain"></el-image>
                </div>
                <el-upload
                  class="uploader"
                  :disabled="allDisable"
                  list-type="picture-card"
                  :action="$baseUrl+'/back/common/image/upload'"
                  :headers="$headers"
                  :show-file-list="false"
                  :limit="1"
                  :file-list="imgForm.activityImgurl"
                  :on-success="uploadSuccess2"
                  :on-change="uploadChange">
                  <i class="el-icon-plus uploader-icon"></i>
                </el-upload>
              </div>
            </el-form-item>
            <el-form-item label="分享图片" prop="shareimage">
              <p>建议尺寸750*n，用于生成海报，为上传时使用主图生成海报</p>
              <div style="display: flex;flex-wrap: wrap;">
                <div v-for="(img, i) in imgForm.shareimage" :key="i" class="imgList-box">
                  <i class="el-icon-error img-delete" @click="imgDelete(i, 'shareimage')"></i>
                  <el-image
                    class="imgList-item"
                    :src="img.url"
                    fit="contain"></el-image>
                </div>
                <el-upload
                  class="uploader"
                  :disabled="allDisable"
                  list-type="picture-card"
                  :action="$baseUrl+'/back/common/image/upload'"
                  :headers="$headers"
                  :show-file-list="false"
                  :limit="1"
                  :file-list="imgForm.shareimage"
                  :on-success="uploadSuccess3"
                  :on-change="uploadChange">
                  <i class="el-icon-plus uploader-icon"></i>
                </el-upload>
              </div>

            </el-form-item>
            <el-form-item label="背景颜色" prop="backgroundColor">
              <el-color-picker v-model="imgForm.backgroundColor" :disabled="allDisable"></el-color-picker>
            </el-form-item>
            <el-form-item label="标题颜色" prop="titleColor">
              <el-color-picker v-model="imgForm.titleColor" :disabled="allDisable"></el-color-picker>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
    </div>
    <el-drawer
      :append-to-body="true"
      :title="'新增产品'"
      :visible.sync="drawerShow"
      size="40%">
      <div class="drawer-content">
        <div class="drawer-content-main">
          <createPage ref="forms" @cancelForm="cancelForm" @searchList="getproductList(ruleForm.masterCode)"></createPage>
        </div>
        <div class="drawer-bottom">
          <el-button @click="cancelForm">取 消</el-button>
          <el-button type="primary" @click="submitPro" :loading="submitloading">{{ submitloading ? '提交中 ...' : '确定' }}</el-button>
        </div>
      </div>
    </el-drawer>
    <el-dialog title="新增备注项"
      :append-to-body="true"
      :visible.sync="showRemark" width="500px">
      <el-form :model="remarkForm" ref="remarkForm" :rules="remarkRule" label-position="left" label-width="100px">
        <el-form-item label="备注项名称" prop="remarksName">
          <el-input :disabled="allDisable" v-model="remarkForm.remarksName" placeholder="请输入备注项名称"></el-input>
        </el-form-item>
        <el-form-item label="字段类型" prop="fieldType">
          <el-select :disabled="allDisable" v-model="remarkForm.fieldType" placeholder="请选择" clearable>
            <el-option label="文本" value="text"></el-option>
            <el-option label="多选项" value="checkbox"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否必填" prop="isMust">
          <el-switch
            :active-value="1"
            :inactive-value="0"
            v-model="remarkForm.isMust"
            :disabled="allDisable">
          </el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showRemark = false">取 消</el-button>
        <el-button type="primary" @click="submitRemark">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import draggable from 'vuedraggable';
import createPage from '@/components/chanpin/create.vue'
export default {
  name: 'shangjiaCreate',
  props: {
  },
  components: { draggable, createPage },
  data() {
    return {
      pageKey: new Date().getTime(),
      editFlag: false,
      allDisable: false,
      currentTabIndex: 0,
      ruleForm: {
      },
      initForm: {
        masterCode: '',
        shopCode: '',
        shopCodesList: [],
        productCode: '',
        activityName: '',
        sort: '',
        startDate: null,
        expireDate: null,
        openGroup: '',
        isGroup: 0,
        discountValue: '1',
        everyoneNumber: '',
        stockNumber: '9999',
        remarksStart: '0',
        online: 1,
        music: 1,
        musictype: 0,
        platCmsn: '',
        giftQty: '',
        giftProduct: '',
        tips: '',
        hongbaoEnable: 0,
        virtually: 0,
        shareCmsn: '0',
        shareCmsnTwo: '0',
        firstOrderReward: '0',
        hongbao: '0',
        limitWithdraw: '200',
        afterLimitWithdraw: '0',
        browseNum: '',
        saleNum: ''
      },
      remarksList: [],//备注项
      rules: {
        masterCode: [
          { required: true, message: '请选择商家', trigger: 'blur' },
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        productCode: [
          { required: true, message: '请选择产品', trigger: 'blur' }
        ],
        startDate: [
          { required: true, message: '请选择开始时间', trigger: 'change' }
        ],
        expireDate: [
          { required: true, message: '请选择有效时间', trigger: 'change' }
        ],
        stockNumber: [
          { required: true, message: '请输入库存数量', trigger: 'blur' }
        ],
        online: [
          { required: true, message: '请选择是否上架', trigger: 'blur' }
        ],
        shareCmsn: [
          { required: true, message: '请输入好友下单', trigger: 'blur' }
        ],
        firstOrderReward: [
          { required: true, message: '请输入首单奖励', trigger: 'blur' }
        ],
        hongbao: [
          { required: true, message: '请输入拆红包奖励', trigger: 'blur' }
        ],
        limitWithdraw: [
          { required: true, message: '请输入首次提现限额', trigger: 'blur' }
        ],
        afterLimitWithdraw: [
          { required: true, message: '请输入后续提现限额', trigger: 'blur' }
        ],
      },
      initImgForm: {
        activityDetailImgurl: [],
        activityImgurl: [],
        shareimage: [],
        backgroundColor: '#F5F4FE',
        titleColor: '#845AF5'
      },
      jsonObject: {
        background: '',
        titlecolor: ''
      },
      imgForm: {
        activityDetailImgurl: [],
        activityImgurl: [],
        shareimage: [],
        backgroundColor: '',
        titleColor: ''
      },
      imgFormRules: {
        activityDetailImgurl: [
          { required: true, message: '请上传活动主图', trigger: 'blur' },
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        activityImgurl: [
          { required: true, message: '请上传活动首页列表图', trigger: 'blur' },
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ]
      },
      curStartTime: '2023-10-19',
      curEndTime: '2024-04-01',
      day: '10',
      hour: '13',
      min: '14',
      second: '52',
      rankList: [{
        avatar: require('../../assets/head1.png'),
        name: '小白',
        phone: 13988976750,
        amount: '￥300',
        status: '已支付',
        time: '2024-03-09 13:22:19',
        income: 100
      },{
        avatar: require('../../assets/head2.png'),
        name: '小白',
        phone: 13988976750,
        amount: '￥300',
        status: '已支付',
        time: '2024-03-09 13:22:19',
        income: 65
      },{
        avatar: require('../../assets/head3.png'),
        name: '张三',
        phone: 13988922752,
        amount: '￥300',
        status: '已支付',
        time: '2024-03-09 13:22:19',
        income: 50
      },{
        avatar: require('../../assets/head1.png'),
        name: '小李',
        phone: 19989657321,
        amount: '￥300',
        status: '已支付',
        time: '2024-03-09 13:22:19',
        income: 35
      },{
        avatar: require('../../assets/head2.png'),
        name: '小白2',
        phone: 15588286638,
        amount: '￥300',
        status: '已支付',
        time: '2024-03-09 13:22:19',
        income: 32
      }],
      masterList: [], //商家下拉列表
      shopList: [], //门店列表
      productList: [], //产品列表
      tabIndex: '0',
      drawerShow: false,
      submitloading: false,
      showRemark: false,
      remarkInitForm: {
        remarksName: '',
        fieldType: '',
        isMust: 0
      },
      remarkForm: {},
      remarkRule:{
        remarksName: [
          { required: true, message: '请输入备注项名称', trigger: 'blur' },
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        fieldType: [
          { required: true, message: '请选择字段类型', trigger: 'blur' },
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ]
      },
      yj: '--'
    };
  },
  mounted() {
    // this.countTime()
  },
  methods: {
    openPro() {
      this.drawerShow = true
      setTimeout(() => {
        this.$refs.forms.resetForm()
        this.$refs.forms.getData()
      }, 0)
    },
    submitPro() {
      this.$refs.forms.submitForm()
    },
    cancelForm() {
      this.submitloading = false
      this.$refs.forms.resetForm()
      this.drawerShow = false
    },
    getData(data) {
      this.allDisable = false
      this.editFlag = false
      this.currentTabIndex = 0
      this.getMasterList()
      this.tabIndex = '0'
      this.pageKey = new Date().getTime()
      if (data) {
        this.editFlag = true
        this.getDetails(data)
      } else {
        this.ruleForm = {...this.initForm}
        this.imgForm = {...this.initImgForm}
        this.masterList = []
        this.shopList = []
        this.remarksList = []
        this.remarkForm = {...this.remarkInitForm}
      }
      console.log(this.editFlag, this.imgForm)
    },
    getDetails(row) {
      // 获取详情数据
      let url = '/back/activity/seckill/eidtView'
      let query = this.$querystring.stringify({
        activityCode: row.activityCode,
        masterCode: row.masterCode
      })
      this.$axios.post(`${url}?${query}`).then((res) => {
        let data = res.data.data
        if (res.data.code == 200) {
          this.getshopList(data.masterCode)
          this.getproductList(data.masterCode)
          this.ruleForm = data
          this.ruleForm.startDate = this.$moment(this.ruleForm.startDate).format('yyyy-MM-DD HH:mm:ss')
          this.ruleForm.expireDate = this.$moment(this.ruleForm.expireDate).format('yyyy-MM-DD HH:mm:ss')
          this.imgForm.activityDetailImgurl = this.ruleForm.activityDetailImgurl.split(',').map(item =>({url: item})) || []
          this.imgForm.activityImgurl = this.ruleForm.activityImgurl.split(',').map(item =>({url: item})) || []
          this.imgForm.shareimage = []
          if (this.ruleForm.jsonObject && this.ruleForm.jsonObject != '') {
            this.imgForm.shareimage = JSON.parse(this.ruleForm.jsonObject).activitShareImgurl.split(',').map(item =>({url: item})) || []
          }
          this.imgForm.backgroundColor = this.ruleForm.jsonObject.background
          this.imgForm.titleColor = this.ruleForm.jsonObject.titleColor
          if (this.ruleForm.remarksJson && this.ruleForm.remarksJson != '') {
            this.remarksList = JSON.parse(this.ruleForm.remarksJson)
          }
        }
      }).catch(()=>{})
    },
    getMasterList() {
      // 获取商家列表
      let url = '/back/masteruser/selectlist'
      this.$axios.post(`${url}`).then((res) => {
        let data = res.data.data
        if (res.data.code == 200) {
          this.masterList = data.rows || []
        }
      }).catch(()=>{ this.listLoading = false })
    },
    masterChange(e) {
      console.log(e)
      this.ruleForm.shopCodesList = []
      this.ruleForm.productCode = ''
      this.getshopList(e)
      this.getproductList(e)
    },
    getshopList(masterCode) {
      // 根据商家获取门店  /back/master/shop/shopListByMasterCode
      let url = '/back/master/shop/shopListByMasterCode'
      this.$axios.post(`${url}?masterCode=${masterCode}`).then((res) => {
        let data = res.data.data
        if (res.data.code == 200) {
          this.shopList = data.data || []
        }
      }).catch(()=>{ this.listLoading = false })
    },
    getproductList(masterCode) {
      // 根据商家获取产品  /back/product/selectlist
      let url = '/back/product/selectlist'
      this.$axios.get(`${url}?masterCode=${masterCode}`).then((res) => {
        let data = res.data.data
        if (res.data.code == 200) {
          this.productList = data.data || []
        }
      }).catch(()=>{ this.listLoading = false })
    },
    productChange(e) {
      let obj = this.productList.find((item) => {
        return item.productCode == e
      })
      console.log(e, obj)
      this.yj = obj.price
      this.ruleForm.product = obj
    },
    setDisable() {
      this.allDisable = true
    },
    submitForm() {
      this.$message.closeAll()
      let flag1 = false
      let flag2 = false
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          flag1 = true
        } else {
          console.log('error submit!!');
          if (this.currentTabIndex == 1) {
            this.$message.error('活动配置存在未填写的内容！')
          }
          return false;
        }
      });
      this.$refs['imgForm'].validate((valid) => {
        if (valid) {
          flag2 = true
        } else {
          console.log('error submit!!');
          if (this.currentTabIndex == 0) {
            this.$message.error('图片配置存在未填写的内容！')
          }
          return false;
        }
      });
      if (flag1 && flag2) {
        this.saveData()
      }
    },
    saveData() {
      // 保存数据
      let url = ''
      let data = {...this.ruleForm}
      let imgData = {
        activityDetailImgurl: this.imgForm.activityDetailImgurl.map((item) => item.url).join(','),
        activityImgurl: this.imgForm.activityImgurl.map((item) => item.url).join(','),
        // masterLogo: JSON.stringify({
        //   shareimage: this.imgForm.shareimage.map((item) => item.url).join(',')
        // })
      }
      let jsonObject = {
        background: this.imgForm.backgroundColor,
        titlecolor: this.imgForm.titleColor,
        activitShareImgurl: this.imgForm.shareimage.map((item) => item.url).join(',')
      }
      let remarksJson = JSON.stringify([...this.remarksList])
      data = {...data, ...imgData}
      data.shopCode = data.shopCodesList.join(',')
      data.jsonObject = jsonObject
      data.remarksJson = remarksJson
      console.log(data)
      if (!this.editFlag) {
        // 新增
        data.discountType = 'fixedprice'
        data.oldVipMemberQty = 9999999
        url = '/back/activity/seckill/release'
      } else {
        url = '/back/activity/seckill/updateActivity'
      }
      let query = this.$querystring.stringify(data)
      this.$axios.post(`${url}?${query}`, data).then((res)=> {
        if (res.data.code == 200) {
          this.$message.success(res.data.msg)
          this.$emit('searchList')
          this.$emit('cancelForm')
        }
      })
    },
    resetForm() {
      this.$refs.ruleForm.clearValidate();
    },
    openRemark(item) {
      // 新增备注项弹框
      this.showRemark = true
      if (item) {
        this.remarkForm = {...item}
      } else {
        this.remarkForm = {...this.remarkInitForm}
      }
      setTimeout(()=>{
        this.$refs.remarkForm.clearValidate()
      },0)
    },
    submitRemark() {
      this.$refs.remarkForm.validate((valid) => {
        if (valid) {
          this.showRemark = false
          this.remarksList.push(this.remarkForm)
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    deleteRemark(i) {
      this.remarksList.splice(i, 1)
    },
    countTime () {
      // 获取当前时间
      let date = new Date()
      let now = date.getTime()
      // 设置截止时间
      let endDate = new Date(this.curEndTime) // this.curStartTime需要倒计时的日期
      let end = endDate.getTime()
      // 时间差
      let leftTime = end - now
      // 定义变量 d,h,m,s保存倒计时的时间
      if (leftTime >= 0) {
        // 天
        this.day = Math.floor(leftTime / 1000 / 60 / 60 / 24)
        // 时
        let h = Math.floor(leftTime / 1000 / 60 / 60 % 24)
        this.hour = h < 10 ? '0' + h : h
        // 分
        let m = Math.floor(leftTime / 1000 / 60 % 60)
        this.min = m < 10 ? '0' + m : m
        // 秒
        let s = Math.floor(leftTime / 1000 % 60)
        this.second = s < 10 ? '0' + s : s
      } else {
        this.day = 0
        this.hour = '00'
        this.min = '00'
        this.second = '00'
      }
      // 等于0的时候不调用
      if (Number(this.hour) === 0 && Number(this.day) === 0 && Number(this.min) === 0 && Number(this.second) === 0) {
        return
      } else {
      // 递归每秒调用countTime方法，显示动态时间效果,
        setTimeout(this.countTime, 1000)
      }
    },
    tabChange(e) {
      console.log(e)
      this.currentTabIndex = e.index
    },
    uploadSuccess(response, file, fileList) {
      console.log(response, file, fileList)
      if (response.code == 200) {
        // 上传成功
        this.imgForm['activityDetailImgurl'].push({
          url: response.data
        })
      }
    },
    uploadChange(file, fileList) {
      console.log(fileList)
    },
    uploadSuccess2(response, file, fileList) {
      console.log(response, file, fileList)
      if (response.code == 200) {
        // 上传成功
        this.imgForm['activityImgurl'].push({
          url: response.data
        })
      }
    },
    uploadSuccess3(response, file, fileList) {
      console.log(response, file, fileList)
      if (response.code == 200) {
        // 上传成功
        this.imgForm['shareimage'].push({
          url: response.data
        })
      }
    },
    imgDelete(i, key) {
      this.imgForm[key].splice(i, 1)
    },
    imgMove(relatedContext, draggedContext) {
      console.log(relatedContext);
      console.log(draggedContext);
    }
  }
}
</script>
<style scoped>
.huodongPage {
  display: flex;
  width: 100%;
  padding-bottom: 20px;
}
.appPreview {
  margin-right: 25px;
}
.appPreviewPage {
  width: 375px;
  min-height: 667px;
  background: #F5F4FE;
  border: 1px solid #F5F4FE;
  padding-bottom: 20px;
}
.formBox {
  width: calc(100% - 450px)
}
.productCard {
  background: #EF522A;
  color: #fff;
  font-size: 13px;
  border-radius: 8px;
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.productCard-left>p:first-child {
  margin-bottom: 6px;
}
.bigTxt {
  font-size: 16px;
}
.smallTxt {
  font-size: 12px;
}
.deleteTxt {
  text-decoration: line-through;
}
.productCard-right {
  text-align: right;
}
.countdown-box {
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-top: 8px;
}
.timeCard {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  background: #fff;
  color: #EF522A;
  line-height: 20px;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
}
.myCard {
  background: #fff;
  font-size: 13px;
  border-radius: 8px;
  padding: 10px 15px;
  margin-top: 20px;
}
.myCard-title {
  font-size: 14px;
}
.rankbox {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.rankbox-left {
  display: flex;
  align-items: center;
  margin-top: 15px;
}
.rankItem {
  margin-right: 5px;
  position: relative;
  border-radius: 50%;
}
.rankItem>.rankImg {
  width: 25px;
  height: 20px;
  position: absolute;
  top: -12px;
  left: -2px;
}
.rankItem>.avatar {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #eee;
}
.rankMore {
  display: flex;
  margin-left: 10px;
}
.rankMore-item {
  margin-right: -10px;
}
.rankMore-item-last {
  border: 1px solid #eee;
  box-sizing: border-box;
  text-align: center;
  line-height: 11px;
  color: #EF522A;
  background: #fff;
  width: 20px;
  height: 20px;
}
.recordTxt {
  margin-bottom: 5px;
}
.recordTxt>span {
  color: #EF522A;
}
.redTxt {
  color: #EF522A;
}
.myBtn-box {
  display: flex;
  justify-content: center;
  margin-top: 25px;
  margin-bottom: 25px;
}
.myBtn {
  width: 60%;
  background: #845AF5;
  color: #fff;
  font-size: 13px;
  line-height: 40px;
  border-radius: 20px;
  text-align: center;
}
.hasborder {
  border-bottom: 1px solid #eee;
}
.card-list {
  display: flex;
  flex-direction: column;
}
.card-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 60px;
  line-height: 22px;
}
.card-list-item-left {
  display: flex;
  align-items: center;
}
.card-list-item-left>img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 5px;
}
.card-list-item-right {
  text-align: right;
}
.card-list2 .card-list-item-left {
  align-items: center;
}
.card-list2 .card-list-item-left img {
  width: 30px;
  height: 30px;
}
.card-list2 .card-list-item-left .rankImg {
  width: 24px;
  height: 24px;
}
.imgList-box {
  position: relative;
}
.img-delete {
  position: absolute;
  top: -10px;
  right: 0;
  font-size: 24px;
  color: #f56c6c;
  z-index: 2;
}
</style>
<style>
.el-tag {
  margin-left: 10px;
}
</style>

<template>
  <div class="commonListPage">
    <div class="list-opt" style="justify-content: flex-end;">
      <div style="display: flex;align-items: center;flex-wrap:wrap">
        <el-date-picker
          style="margin-right: 10px;width: 360px"
          v-model="dateTime"
          type="daterange"
          value-format="yyyy-MM-dd"
          placeholder="选择时间范围">
        </el-date-picker>
        <div style="margin-right: 10px">
          <span style="font-size: 14px;margin-right: 10px">状态</span>
          <el-select
            @change="searchList"
            v-model="params.orderStatus"
            placeholder="请选择" style="width: 120px" clearable>
            <el-option label="待打款" :value="1"></el-option>
            <el-option label="提现成功" :value="2"></el-option>
            <el-option label="提现失败" :value="3"></el-option>
            <el-option label="处理中" :value="4"></el-option>
            <el-option label="待审核" :value="6"></el-option>
            <el-option label="审核不通过" :value="7"></el-option>
            <el-option label="审核通过待打款" :value="8"></el-option>
          </el-select>
        </div>
        <div style="width: 300px;margin-right: 10px">
          <el-input placeholder="请输入内容" v-model="searchTxt" class="input-with-select">
            <el-select v-model="searchTxtType" slot="prepend" placeholder="请选择" clearable>
              <el-option label="商家名称" value="masterName"></el-option>
              <el-option label="提现金额小于" value="withdrawMoneyStart"></el-option>
              <el-option label="提现金额大于" value="withdrawMoneyEnd"></el-option>
              <el-option label="手机号" value="tel"></el-option>
            </el-select>
            <el-button slot="append" icon="el-icon-search" @click="searchList"></el-button>
          </el-input>
        </div>
        <el-button type="primary" @click="exportData">导出</el-button>
      </div>
    </div>
    <div class="info-list">
      <el-row :gutter="20">
        <el-col :xs="24" :sm="8" :md="4" :lg="4" :xl="4" v-for="(item, i) in initInfoList" :key="i">
          <div class="info-content">
            <span>{{item.name}}</span>
            <span>{{attachment[item.key]}}</span>
          </div>
        </el-col>
      </el-row>
    </div>
    <div ref="tableWrap" style="height: calc(100% - 156px)" v-loading="listLoading">
      <el-table
        border
        :data="tableData"
        size="small"
        style="width: 100%;"
        :height="tableHeight"
        :row-class-name="tableRowClassName">
        <el-table-column
          v-for="(col, i) in column"
          :key="i"
          :fixed="col.fixed || false"
          :prop="col.prop"
          :label="col.label"
          :width="col.width">
          <template slot-scope="scope">
            <template v-if="col.prop == 'action'">
              <el-button v-if="scope.row.orderStatus==6" type="primary" icon="el-icon-check" plain size="small" @click="openFxr(scope.row)"></el-button>
            </template>
            <template v-else-if="col.prop == 'platType'">
              <span>{{platTypeMap[scope.row.platType]}}</span>
            </template>
            <template v-else-if="col.prop == 'orderStatus'">
              <span>{{orderStatusMap[scope.row.orderStatus]}}</span>
            </template>
            <template v-else-if="col.prop == 'withdrawType'">
              <span>{{withdrawTypeMap[scope.row.withdrawType]}}</span>
            </template>
            <template v-else-if="col.prop == 'returnMsg'">
              <span v-if="scope.row.orderStatus != 2">{{scope.row.returnMsg}}</span>
            </template>
            <span v-else>
              {{  scope.row[col.key] }}
            </span>
          </template>
        </el-table-column>
      </el-table>
      <div class="bottom-pagination">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="params.currPage"
          :page-sizes="[5, 10, 15, 20]"
          :page-size="params.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalCount">
        </el-pagination>
      </div>
    </div>
    <el-dialog title="审批" :visible.sync="showPZflag" width="600px">
      <el-form label-width="120px">
        <el-form-item label="审核" prop="check">
          <el-radio-group v-model="orderState">
            <el-radio :label="8">同意</el-radio>
            <el-radio :label="7">不同意</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="审批描述" prop="remark">
          <el-input type="textarea" :rows="2" v-model="reviewerReason" placeholder="请输入审批描述"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showPZflag = false">取 消</el-button>
        <el-button type="primary" @click="submitPZ">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'dingdanList',
  props: {
  },
  components: {
  },
  data() {
    return {
      dateTime: '',
      searchTxt: '',
      searchTxtType: 'masterName',
      attachment: {},
      infoList: [{
        name: '总提现金额',
        key: 'sumwithdrawmoney',
        val: ''
      },{
        name: '总手续费',
        key: 'sumservicemoney',
        val: ''
      }],
      initInfoList: [],
      openFlag: false,
      tableData: [],
      column: [{
        label: '商家名称',
        prop: 'masterName',
        key: 'masterName',
        width: 120
      },{
        label: '单号',
        prop: 'orderNo',
        key: 'orderNo',
        width: 140
      },
      // {
      //   label: '订单号',
      //   prop: 'orderNo',
      //   key: 'orderNo',
      //   width: 120
      // },
      {
        label: '时间',
        prop: 'createTime',
        key: 'createTime',
        width: 140
      },{
        label: '提现金额',
        prop: 'actualWithdrawMoney',
        key: 'actualWithdrawMoney',
        width: 100
      },{
        label: '手续费',
        prop: 'serviceMoney',
        key: 'serviceMoney',
        width: 100
      },{
        label: '提现类型',
        prop: 'platType',
        key: 'platType',
        width: 120
      },{
        label: '状态',
        prop: 'orderStatus',
        key: 'orderStatus',
        width: 120
      },{
        label: '微信昵称', // 0
        prop: 'accountName',
        key: 'accountName',
        width: 150
      },{
        label: '联系电话',
        prop: 'contact',
        key: 'contact',
        width: 120
      },{
        label: '提现账户类型', // 0
        prop: 'withdrawType',
        key: 'withdrawType',
        width: 120
      },{
        label: '支付宝账户',
        prop: 'alipayAccount',
        key: 'alipayAccount',
        width: 120
      },{
        label: '支付宝真实姓名',
        prop: 'alipayName',
        key: 'alipayName',
        width: 120
      },{
        label: '审核人',
        prop: 'reviewer',
        key: 'reviewer',
        width: 120
      },{
        label: '审核意见',
        prop: 'reviewerReason',
        key: 'reviewerReason',
        width: 140
      },{
        label: '失败原因',
        prop: 'returnMsg',
        key: 'returnMsg',
        width: 140
      },{
        label: '审核时间',
        prop: 'reviewerTime',
        key: 'reviewerTime',
        width: 140
      },{
        label: '操作',
        prop: 'action',
        key: 'action',
        width: 120,
        fixed: 'right'
      }],
      params: {
        currPage: 1,
        pageSize: 10,
        withdrawType: 'wallet'
      },
      totalCount: 0,
      tableHeight: 400,
      drawerTitle: '新增',
      drawerShow: false,
      submitloading: false,
      showTXflag: false, //商家提现弹窗
      txData: {}, //商家提现对象
      showPZflag: false, //批注弹窗显示
      reviewerReason: '',
      orderState: 8,
      showQRcodeflag: false, //员工二维码弹窗
      showRecord: false, //员工记录弹窗
      orderStatusMap: {
        // 1:创建订单 2:提现成功 3:提现失败 4:处理中（提现中）6待审核 7已拒绝 8审核通过待打款
        1: '待打款',
        2: '提现成功',
        3: '提现失败',
        4: '提现中',
        6: '待审核',
        7: '审核不通过',
        8: '审核通过待打款'
      },
      withdrawTypeMap: {
        'alipay': '支付宝',
        'wallet': '微信'
      },
      platTypeMap: {
        //提现类型 1、商家提现；2、门店提醒；3、佣金提现；4、差额返现
        1: '商家提现',
        2: '门店提现',
        3: '佣金提现',
        4: '差额返现',
        5: '活动红包提现'
      },
      listLoading: true,
      currentRow: null
    };
  },
  mounted() {
    this.setTableHeight()
    this.searchList()
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    // 移除事件监听
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    tableRowClassName({row, rowIndex}) {
      if (rowIndex%2 == 1) {
        return 'highlight-row';
      }
      return row.key + '';
    },
    handleResize() {
      console.log('窗口变')
      this.setTableHeight()
    },
    searchList() {
      delete this.params.masterName
      delete this.params.withdrawMoneyStart
      delete this.params.withdrawMoneyEnd
      delete this.params.tel
      this.params.currPage = 1
      this.params.pageSize = 10
      this.getData()
    },
    getData() {
      this.params[this.searchTxtType] = this.searchTxt
      this.dateTime = this.dateTime || []
      this.params.timeStart = this.dateTime[0] || ''
      this.params.timeEnd = this.dateTime[1] || ''
      this.listLoading = true
      let url = '/back/withdrawal/list'
      let queryForm = {...this.params}
      let query = this.$querystring.stringify(queryForm)
      this.$axios.post(`${url}?${query}`).then((res) => {
        this.listLoading = false
        let data = res.data.data
        if (res.data.code == 200) {
          this.tableData = data.list || []
          this.totalCount = data.totalCount
          this.attachment = data.attachment || {}
        }
      }).catch(()=>{ this.listLoading = false })
    },
    handleSizeChange(pageSize) {
      this.params.pageSize = pageSize
      this.getData()
    },
    handleCurrentChange(page) {
      this.params.currPage = page
      this.getData()
    },
    setTableHeight() {
      this.$nextTick(() => {
        if (this.smFlag == 1) {
          console.log('小小小')
          this.column[this.column.length -1].fixed = false
        }
        this.tableHeight = this.$refs.tableWrap.clientHeight
        console.log(this.tableHeight)
      })
    },
    create(type, row) {
      this.drawerShow = true
      if (type == 0) {
        this.drawerTitle = '新增'
        setTimeout(() => {
          this.$refs.forms.resetForm()
          this.$refs.forms.getData()
        }, 0)
      }
      if (type == 1) {
        this.drawerTitle = '编辑产品'
        setTimeout(() => {
          this.$refs.forms.resetForm()
          this.$refs.forms.getData(row)
        }, 0)
      }
    },
    openFxr(row) {
      //  打开商家资料
      this.showPZflag = true
      this.reviewerReason = ''
      this.orderState = 8
      this.currentRow = row
      console.log(row)
    },
    cancelFileFrom() {
      this.$refs.fileFrom.resetForm()
      this.fileShow = false
    },
    submitFileForm() {
      this.$refs.fileFrom.submitForm()
    },
    submitForm() {
      this.$refs.forms.submitForm()
    },
    cancelForm() {
      this.$refs.forms.resetForm()
      this.drawerShow = false
    },
    showTX() {
      this.showTXflag = true
    },
    submitTX() {
      this.showTXflag = false
    },
    submitPZ() {
      // if (this.reviewerReason == '') {
      //   this.$message.warning('请输入审批意见')
      //   return
      // }
      let row = this.currentRow
      let query = this.$querystring.stringify({
        orderNo: row.orderNo,
        orderState: this.orderState,
        reviewerReason: this.reviewerReason
      })
      this.$axios.post(`/back/withdrawal/withdrawMoneyApproval?${query}`).then((res)=> {
        if (res.data.code == 200) {
          this.showPZflag = false
          this.getData()
          this.$message.success(res.data.msg)
        }
      })
    },
    BDswitch(row) {
      if (row.bindingFlag == 0) {
        // 需要绑定
        this.$confirm('是否需要绑定该商家?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          row.bindingFlag = 1
        }).catch(() => {
        });
      }
      if (row.bindingFlag == 1) {
        // 需要解绑
        this.$confirm('是否需要解绑该商家?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          row.bindingFlag = 0
        }).catch(() => {
        });
      }
    },
    showQRcode(row) {
      console.log(row)
      this.showQRcodeflag = true
    },
    backRow(row) {
      this.$confirm('是否撤回该订单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log(row)
      }).catch(() => {
      });
    },
    openRecord() {
      this.showRecord = true
    },
    exportData() {
      // 导出数据
      this.dateTime = this.dateTime || []
      let query = this.$querystring.stringify(this.params)
      this.$axios.get(`/back/withdrawal/listexport?${query}`,
        {
          // //定义类型
          // headers: {
          //   "Content-Type": "application/x-www-form-urlencoded",
          // },
          responseType:'blob',
        }).then((res)=> {
          const file = new Blob([res.data], { type: "application/vnd.ms-excel" });
          const url = URL.createObjectURL(file);
          const a = document.createElement("a");
          a.download = '提现.xls';
          a.href = url;
          a.click();
          this.$message.success('导出成功')
      })
    }
  },
  watch: {
    openFlag: {
      handler: function (val) {
        if (val) {
          this.initInfoList = JSON.parse(JSON.stringify(this.infoList))
        } else {
          this.initInfoList = JSON.parse(JSON.stringify(this.infoList.slice(0, 6)))
        }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>
<style coped>
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
.input-with-select .el-select .el-input {
  width: 100px;
}
.list-opt {
  justify-content: space-between;
}
.info-list {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
}
.info-list>.el-row {
  width: calc(100% - 60px)
}
.info-list-action {
  color: #409EFF;
  font-size: 13px;
  cursor: pointer;
  width: 60px;
  text-align: center;
  line-height: 30px;
}
.info-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  font-size: 12px;
  color: #222;
  background-color: #fff;
  margin-bottom: 12px;
  padding: 0 10px;
}
.info-content>span:last-child {
  color: #409EFF;
}
.drawer-content {
  position: relative;
  height: 100%;
  padding: 0 20px;
}
.drawer-content-main {
  height: calc(100% - 80px);
  overflow: auto;
}
.drawer-bottom {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  border-top: 1px solid #eee;
  text-align: right;
  padding: 0 20px;
  padding-top: 15px;
}
</style>
